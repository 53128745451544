.kwn-sign_in-main_container {
  display: flex;
  align-items: center;
  padding-top: 8rem;
  padding-left: 9.6rem;
  padding-right: 9.6rem;
  padding-bottom: 10rem;
  flex-direction: column;
}

.kwn-sign_in-title_container {
  margin-bottom: 6rem;
}

.kwn-sign_in-title_container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.9rem;
  color: #2d292a;
}

.kwn-sign_in-title_container h2 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #2d292a;
  margin-top: 1rem;
}

.kwn-sign_in-form_container {
  display: flex;
  flex-direction: column;
}

.kwn-sign_in-forgot_container {
  width: 44rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 10rem; */
  padding-top: 1rem;
  padding-bottom: 8rem;
}

.kwn-sign_in-new_to_kavan {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: -0.5px;
  color: #2d292a;
}

.kwn-sign_in-forgot_container span {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: -0.5px;
  color: #006039;
  cursor: pointer;
  text-decoration: underline;
}

.kwn-sign_in-forgot_password {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: -0.5px;
  cursor: pointer;
  color: #006039;

  text-decoration: underline;
}

.kwn-sign_in-terms_container span {
  text-decoration: underline;
}

.kwn-sign_up-Button_container {
  margin-left: 0.5rem;
}

.kwn-sign_in-main_container h3 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  font-size: 2.6rem;
  line-height: 3.6rem;
  color: #2d292a;
  padding-top: 5rem;
}

.kwn-sign_in-social_container {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  width: 43rem;
  margin-top: 5rem;
}

.kwn-sign_in-social_container div {
  display: flex;
  align-items: center;
  border: 0.1rem solid #666666;
  border-radius: 5rem;
  width: 19.5rem;
  padding: 0.8rem 0rem;
  justify-content: space-evenly;
  margin-bottom: 2.5rem;
}

.kwn-sign_in-social_container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #2d292a;
}

.kwn-sign_in-social_container img {
  width: 3rem;
  height: 3rem;
}

@media screen and (max-width: 480px) {
  .kwn-sign_in-social_container {
    width: 30rem;
  }
  .kwn-sign_in-forgot_container {
    width: 30rem;
    padding-bottom: 3rem;
  }
  .kwn-fill_out_form-main_container h3 {
    padding-bottom: 2rem;
  }
  .kwn-sign_in-social_container {
    margin-top: 2rem;
  }
}
