.kwn_search_bar_container {
  width: 100%;
  background-color: rgb(255, 255, 255);
  display: flex;
}

.kwn_search_bar_container div {
  width: 75%;
  height: 6.6rem;
  background-color: #f5f5f5;
  border-radius: 3rem;
  padding: 0 2rem;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.kwn_search_bar_container input {
  width: 90%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 3rem;
  background-color: #f5f5f5;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  color: #666666;
}

.kwn_search_bar_container img {
  width: 2rem;
  margin-right: 2rem;
  margin-left: 1rem;
  height: 2rem;
}

@media screen and (max-width: 520px) {
  .kwn_search_bar_container {
    height: 4rem;
    /* width: 42rem; */
  }

  .kwn_search_bar_container div {
    width: 100%;
    height: 5.6rem;
  }
}
