.kwn-profile-images_top_view {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  position: relative;
}

.phone-input {
  height: 39px;
  width: 426px;
  border: 1px solid #000;
  color: #333333;
  font-size: 18px;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
}

.phone-input:focus {
  outline: 1px solid #eee;
}

.kwn-profile_three_dots {
  position: absolute;
  right: 2.6rem;
  top: 2.9rem;
}

.kwn-profile_three_dots img {
  width: 0.6rem;
  height: 2.4rem;
}

.kwn-profile-cover_image img {
  width: 100%;
  height: 28.6rem;
  object-fit: cover;
  border-radius: 0px 0px 3rem 3rem;
}

.kwn-profile_image {
  flex-direction: column;
  align-self: center;
  position: absolute;
  bottom: -11rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.kwn-profile_image img {
  width: 13.5rem;
  height: 13.5rem;
  object-fit: cover;
  border-radius: 7rem;
  border: 2px solid #fff;
}

.kwn-profile_image h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #2d292a;
  padding-top: 1.6rem;
}

.kwn-profile_image h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  color: #666666;
  padding-top: 0.5rem;
}

.kwn-profile-about_me_top_view {
  margin-top: 12.6rem;
  margin-bottom: 5rem;
}

.kwn-profile-about_me h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 3.3rem;
  color: #2d292a;
  padding-bottom: 2rem;
}

.kwn-profile-shadow_box {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 15px rgba(57, 61, 72, 0.15);
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 2.5rem 1rem 2.5rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kwn-profile-shadow_box h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.7rem;
  display: flex;
  align-items: center;
  color: #666666;
}

.kwn-profile-circle_view {
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #006039;
  margin-right: 2rem;
}

.kwn-profile-circle_view img {
  width: 2.8rem;
  height: 2.8rem;
}

.kwn-profile-shadow_box h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.3rem;
  display: flex;
  align-items: center;
  color: #2d292a;
}

.kwn-profile-availablity_content {
  display: flex;
  flex-direction: column;
}

.kwn-profile-availablity_content h5 {
  padding-top: 0.5rem;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.1rem;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #666666;
}

.kwn-profile_specialist_view {
  display: flex;
  flex-direction: column;
}

.kwn-profile_specialist_tag_top_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.kwn-profile_specialist_tag_view {
  margin-top: 1rem;
  margin-right: 1.5rem;
  padding: 1.2rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #006039;
  border-radius: 20px;
}

.kwn-profile_specialist_tag_view p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: center;
  color: #ffffff;
}

.kwn-profile_specialist_tag_view img {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 2rem;
}

.edit-btn-position-container {
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: 3rem;
  bottom: 6rem;
}

@media screen and (max-width: 420px) {
  .kwn-profile-images_top_view {
    margin-top: 1rem;
  }
}