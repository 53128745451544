.kavan_admin_main_container {
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding: 0rem 4rem;
}

.kavan_admin_main_container::-webkit-scrollbar {
  width: 0 !important;
}

.kavan_admin_main_container {
  overflow: -moz-scrollbars-none;
}

.kavan_admin_main_container {
  -ms-overflow-style: none;
}

.kwn-heading {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 5.6rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  color: #2d292a;
}

.kwn-search {
  padding: 5rem 0rem;
}

.kwn-dashboard-title_container {
  padding-bottom: 5rem;
}

.kwn-dashboard-title_container h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4.2rem;
  color: #2d292a;
}

.kwn-dashboard-title_container h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 3.2rem;
  color: #666666;
}

.kwn-dashboard-appointments_and_patients_top_view {
  padding-bottom: 5rem;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
}

.kwn-dashboard-appointments_and_patients_view {
  width: 34rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 6.7rem;
  border-radius: 3rem;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  margin-right: 5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.kwn-dashboard-round_calender_view {
  width: 6.3rem;
  height: 6.2rem;
  border-radius: 5rem;
  background-color: #006039;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 1.8rem;
}

.kwn-dashboard-round_calender_view img {
  width: 3rem;
  height: 3rem;
}

.kwn-dashboard-appointments_and_patients_view h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 2.9rem;
  color: #2d292a;
}

.kwn-dashboard-appointments_and_patients_view h3 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.3rem;
  color: #666666;
  padding-top: 0.5rem;
}

.kwn-dashboard-arrows_top_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.kwn-dashboard-arrows_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10.5rem;
}

.kwn-dashboard-arrows_view div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 2rem;
  background-color: #006039;
  margin-left: 3.1rem;
}

.kwn-dashboard-arrows_view img {
  width: 1.5rem;
  height: 1.2rem;
}

.kwn-dashboard-total_patients_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 7rem;
}

.kwn-dashboard-total_patients_view h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  color: #2d292a;
  padding-right: 2.3rem;
}

.kwn-dashboard-total_patients_view h4 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-transform: uppercase;
  color: #2d292a;
  padding-right: 1.1rem;
}

.kwn-dashboard-total_patients_view h5 {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  align-items: center;
  color: #666666;
}

.kwn-dashboard-graph_view {
  padding-bottom: 7rem;
}

.kwn-dashboard-appointments_top_view {
  padding-bottom: 5rem;
}

.kwn-dashboard-appointment_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.kwn-dashboard-resume_container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 3.3rem;
  line-height: 5.4rem;
  align-items: center;
  color: #2d292a;
  margin-bottom: 1rem;
}

.kwn-dashboard-solicitud-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 61.8rem;
  min-height: 6.2rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 15px rgba(57, 61, 72, 0.1);
  border-radius: 12px;
  margin-bottom: 5rem;
}
.kwn-dashboard-solicitud-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.kwn-dashboard-side_line {
  width: 5px;
  height: 44px;
  background: #f8b84e;
  border-radius: 10px;
}

.kwn-dashboard-solicitud-tag p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  color: #2d292a;
  margin-left: 2rem;
}

.kwn-dashboard-solicitud-btn {
  width: 14.9rem;
  height: 3.5rem;
  background: #f8b84e;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-radius: 6rem;
  margin-right: 2rem;
}
.kwn-dashboard-solicitud-btn p {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.6rem;
  color: var(--color-theme);
}
/* --------Media Query------ */

@media screen and (max-width: 1420px) {
  .kwn-dashboard-appointments_and_patients_view {
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .kwn-dashboard-solicitud-container {
    width: 50rem;
  }
}

@media screen and (max-width: 520px) {
  .kwn-dashboard-appointment_view {
    justify-content: center;
  }
  #appointment-container {
    padding: 0rem 2rem !important
  }
  .kwn-dashboard-arrows_top_view {
    width: 41rem;
  }
  .kwn-heading {
    font-size: 2.6rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
  }
  .kwn-dashboard-solicitud-container {
    width: 32rem;
  }
  .kwn-heading {
    font-size: 2rem;
  }
  .kwn-dashboard-arrows_top_view {
    width: 33rem;
  }
  .kavan_admin_main_container {
    padding: 0rem 2rem;
  }
}
@media screen and (max-width: 420px) {
  .kwn-dashboard-appointments_top_view {
    padding-bottom: 20rem;
  }
  .kwn-dashboard-appointments_and_patients_view {
    padding-left: 1.6rem;
    margin-right: 0rem;
  }
  .kwn-dashboard-solicitud-container {
    width: 32rem;
  }
}
